<template>
  <div style="padding: 15px">
    <el-menu style="margin-bottom: 5px" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item v-for="(item, index) in menuItemList" :key="index" :index="index" @click="goPage(item)">{{item.name}}</el-menu-item>
    </el-menu>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            activeIndex: 0,
            menuItemList: [
                {
                    name: '认证',
                    path: '/userCenter1'
                },
                {
                    name: '修改密码',
                    path: '/accountSafe'
                },
                {
                    name: '我的地址',
                    path: '/address'
                }
            ]
        }
    },
    methods: {
        goPage(item) {
            this.$router.push(item.path)
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__item.is-active {
    color: $commonThemeColor!important;
}
</style>